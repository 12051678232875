:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --border-color: #d3d6da;
  --key-bg-color: #d3d6da;
  --key-text-color: #000000;
  --green-color: #6aaa64;
  --yellow-color: #c9b458;
  --grey-color: #787c7e;
  --guessed-key-bg-color: #787c7e;
  --guessed-key-text-color: #ffffff;
  --guess-box-size: calc(min(60px, (100vw - 40px) / var(--word-length, 5)));
  --guess-box-font-size: calc(var(--guess-box-size) * 0.6);
  --modal-bg-color: #ffffff;
  --modal-text-color: #000000;
}

.dark-mode {
  --bg-color: #121213;
  --text-color: #ffffff;
  --border-color: #3a3a3c;
  --key-bg-color: #818384;
  --key-text-color: #ffffff;
  --green-color: #538d4e;
  --yellow-color: #b59f3b;
  --grey-color: #3a3a3c;
  --guessed-key-bg-color: #3a3a3c;
  --guessed-key-text-color: #ffffff;
  --modal-bg-color: #242424;
  --modal-text-color: #ffffff;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

h1 {
  font-size: 2rem;
  margin: 0;
}

.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}


.game-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  padding: 0 10px;
  box-sizing: border-box;
}

.guess-row {
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}

.guess-box {
  width: var(--guess-box-size);
  height: var(--guess-box-size);
  border: 2px solid var(--border-color);
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: clamp(0.8rem, calc(var(--guess-box-size) * 0.6), 1.5rem);
  box-sizing: border-box;
}

.guess-box:last-child {
  margin-right: 0;
}

.guess-box.space {
  background-color: var(--bg-color);
  border: none;
  width: calc(var(--guess-box-size) / 3);
  height: calc(var(--guess-box-size) / 3);
}

.green {
  background-color: var(--green-color);
  color: white;
}

.yellow {
  background-color: var(--yellow-color);
  color: white;
}

.grey {
  background-color: var(--grey-color);
  color: white;
}

.guess-box.current {
  border-color: var(--text-color);
  border-width: 2px;
}

.virtual-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 20px auto 0;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

.keyboard-key {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 5px;
  margin: 0 2px;
  border-radius: 4px;
  background-color: var(--key-bg-color);
  color: var(--key-text-color);
  border: none;
  cursor: pointer;
  min-width: 20px;
  flex-grow: 1;
  max-width: 40px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.key-function {
  font-size: 0.8rem;
  padding: 10px 5px;
  max-width: 60px;
}

.key-correct {
  background-color: var(--green-color);
  color: white;
}

.key-present {
  background-color: var(--yellow-color);
  color: white;
}

.key-absent {
  background-color: var(--grey-color);
  color: white;
}

.key-guessed {
  background-color: var(--guessed-key-bg-color);
  color: var(--guessed-key-text-color);
}

@media (min-width: 768px) {
  :root {
    --guess-box-size: min(80px, 10vw);
  }

  .App {
    max-width: 768px;
  }

  .game-board {
    max-width: 800px;
  }

  .guess-box-content {
    font-size: calc(var(--guess-box-size) * 0.5);
  }

  .keyboard-key {
    font-size: 1.2rem;
    padding: 15px 10px;
    min-width: 40px;
    max-width: 60px;
  }

  .key-function {
    font-size: 1rem;
    max-width: 80px;
  }
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hint-button {
  font-weight: bold;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hint-button:hover {
  background-color: #45a049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--modal-bg-color);
  color: var(--modal-text-color);
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button.cancel {
  font-weight: bold;
  background-color: #765eff;
  color: white;
}

.modal-button.confirm {
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}

.modal-button:hover {
  opacity: 0.8;
}

.game-over {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--bg-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.game-over .success-message {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--green-color);
  margin-bottom: 1rem;
  animation: pulse-green 1.5s infinite;
}

.game-over .failure-message {
  font-size: 1.5rem;
  font-weight: bold;
  color: #e63946; /* Rich red color */
  margin-bottom: 1rem;
  animation: pulse-red 1.5s infinite;
}

@keyframes pulse-green {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

@keyframes pulse-red {
  0% { opacity: 1; color: #e63946; }
  50% { opacity: 0.7; color: #c1121f; } /* Darker red */
  100% { opacity: 1; color: #e63946; }
}

.game-over button {
  margin: 1rem 0;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.game-over button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.game-over .definition {
  font-style: italic;
  font-size: 1.1rem;
  line-height: 1.4;
  text-align: center;
  margin-top: 0.5rem;
}